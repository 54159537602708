import clsx from "clsx";
import { PlanCardType } from "../types/types";
import UpgradeButton from "./UpgradeButton";
import { getUserSubscriptionPlan } from "@/lib/server/pricing/stripe";
import {
  AllPlans,
  AllPlansNoFree,
  extractPlanValue,
  findDefaultPriceOfPlan,
  findOriginalPriceOfPlan,
  PossiblePlanKeys,
  PossiblePlanKeysNoFree,
  PossiblePlans,
  PossiblePlansNoFree,
} from "@/lib/shared/pricing/utils";
import "./styles.css";
import { PricingStoreType } from "../../stores/pricingStore";

export const isAbleToChangePlanFrontend = (
  fromPlan: Awaited<ReturnType<typeof getUserSubscriptionPlan>>,
  toPlan: PossiblePlanKeys,
  allPlans: AllPlans,
): boolean => {
  if (fromPlan.activePlan.billing === "yearly") {
    if (allPlans[toPlan].billing === "yearly" && fromPlan.planType === toPlan)
      return true;
    return false;
  }
  return true;
};

const planStylesBase = {
  freePlan: {
    textDiscountStyle: "",
    upgBtnStyle: "",
    bannerActive: "",
    bannerUpgrade: "",
    outerContainerBase: "",
    outerContainerBaseComingSoon: "",
  },
  goldPlan: {
    textDiscountStyle:
      "bg-gradient-to-r from-yellow-800 to-yellow-600 bg-clip-text text-transparent",
    upgBtnStyle: "bg-yellow-600 hover:bg-yellow-700",
    bannerActive:
      "from-green-400 to-green-500  px-3 py-2 text-sm font-medium text-white",
    bannerUpgrade:
      "from-yellow-600 to-yellow-400 px-3 py-2 text-sm font-medium text-white",
    outerContainerBase:
      "flex flex-col relative border-opacity-25 shadow-black/25 shadow-md bg-gradient-to-r from-yellow-500 to-yellow-300 bg-opacity-75",
    outerContainerBaseComingSoon:
      "flex flex-col relative border-opacity-25 shadow-black/25 shadow-md bg-gradient-to-r from-yellow-500 to-yellow-300 opacity-75 text-opacity-50",
  },
  platinumPlan: {
    textDiscountStyle:
      "bg-gradient-to-r from-slate-700 via-emerald-800 to-emerald-700 bg-clip-text text-transparent",
    upgBtnStyle: "bg-emerald-800 hover:bg-emerald-900",
    bannerActive:
      "from-green-400 to-green-500  px-3 py-2 text-sm font-medium text-white",
    bannerUpgrade:
      "from-emerald-700 to-emerald-500 px-3 py-2 text-sm font-medium text-white",
    outerContainerBase:
      "flex flex-col relative border-opacity-25 shadow-black/25 shadow-md bg-gradient-to-r from-emerald-600 to-emerald-400 bg-opacity-75",
    outerContainerBaseComingSoon:
      "flex flex-col relative border-opacity-25 shadow-black/25 shadow-md bg-gradient-to-r  from-emerald-600 to-emerald-400 opacity-60 text-opacity-50",
  },
  diamondPlan: {
    textDiscountStyle:
      "bg-gradient-to-r from-blue-100 via-slate-200 to-white bg-clip-text text-transparent",
    upgBtnStyle: "bg-blue-600 hover:bg-blue-700",
    bannerActive:
      "from-green-400 to-green-500  px-3 py-2 text-sm font-medium text-white",
    bannerUpgrade:
      "from-blue-600 to-slate-300 px-3 py-2 text-sm font-medium text-white",
    outerContainerBase:
      "flex flex-col relative border-opacity-25 shadow-black/25 shadow-md bg-gradient-to-r from-blue-500 to-blue-300 bg-opacity-75",
    outerContainerBaseComingSoon:
      "flex flex-col relative border-opacity-25 shadow-black/25 shadow-md bg-gradient-to-r from-blue-500 to-blue-300 opacity-60 text-opacity-50",
  },
};

const planStyles = {
  freePlan: planStylesBase.freePlan,
  goldPlan: planStylesBase.goldPlan,
  diamondPlan: planStylesBase.diamondPlan,
  platinumPlan: planStylesBase.platinumPlan,
  goldPlanYearly: planStylesBase.goldPlan,
  diamondPlanYearly: planStylesBase.diamondPlan,
  platinumPlanYearly: planStylesBase.platinumPlan,
};

export const getPlanCss = (arg: PossiblePlanKeys) => {
  return planStyles[arg];
};

export const getOuterContainerStyle = ({
  object,
  planType,
  isComingSoon = false,
}: {
  object:
    | { isViewOnly: boolean; isSubscribedToThisPlan?: never }
    | { isViewOnly?: never; isSubscribedToThisPlan: boolean };
  planType?: PossiblePlanKeysNoFree;
  isComingSoon?: boolean;
}) => {
  const { isViewOnly, isSubscribedToThisPlan } = object;
  const css = getPlanCss(planType!);
  const base = isComingSoon
    ? css.outerContainerBaseComingSoon
    : css.outerContainerBase;

  if (isViewOnly) return base;

  const outerContainerStyle = clsx(
    base,
    !isSubscribedToThisPlan && "border-2 border-violet-600",
    isSubscribedToThisPlan && "border-2 border-green-600",
  );
  return outerContainerStyle;
};

export const shouldShowTrialingDetails = (
  activeSubscriptionPlan: PlanCardType["activeSubscriptionPlan"],
  subscriptionPlanType: PossiblePlanKeys,
) => {
  return (
    !activeSubscriptionPlan.hasSubscribedAtLeastOnce ||
    (activeSubscriptionPlan.stripeSubscriptionStatus === "trialing" &&
      activeSubscriptionPlan.planType === subscriptionPlanType)
  );
};

export const renderCancellationNotice = ({
  activeSubscriptionPlan,
  isSubscribedToThisPlan,
}: {
  activeSubscriptionPlan: PlanCardType["activeSubscriptionPlan"];
  isSubscribedToThisPlan: boolean;
}) => {
  return (
    <>
      {activeSubscriptionPlan.isCanceled &&
        activeSubscriptionPlan.stripeCurrentPeriodEnd &&
        isSubscribedToThisPlan && (
          <div className="w-full absolute shadow-lg top-[100%] bg-black text-white rounded-b-lg">
            <p className="text-center w-full">
              Cancels at{" "}
              {activeSubscriptionPlan.stripeCurrentPeriodEnd.toDateString()}
            </p>
          </div>
        )}
    </>
  );
};

export const getWrapperForPlanBannerStyle = ({
  baseWrapperForPlanBannerStyle,
  isUpgradeOrDowngrade,
  isSubscribedToThisPlan,
  planType,
  discount,
  isComingSoon = false,
}: {
  baseWrapperForPlanBannerStyle: string;
  isUpgradeOrDowngrade: boolean;
  isSubscribedToThisPlan: boolean;
  planType: PossiblePlanKeys;
  discount?: number;
  isComingSoon?: boolean;
}) => {
  const css = getPlanCss(planType);
  if (isComingSoon) {
    return clsx(
      baseWrapperForPlanBannerStyle,
      "px-4 py-3 text-sm font-medium bg-gradient-to-r from-purple-600 to-blue-400 text-white",
    );
  }
  if (isSubscribedToThisPlan)
    return clsx(baseWrapperForPlanBannerStyle, css.bannerActive);

  if (discount && discount > 0)
    return clsx(
      baseWrapperForPlanBannerStyle,
      "px-3 py-2 bg-gradient-to-r from-orange-700 via-orange-500 to-yellow-300 text-white",
    );

  if (isUpgradeOrDowngrade) return "hidden";

  return clsx(baseWrapperForPlanBannerStyle, css.bannerUpgrade);
};

export const renderPriceDisplay = ({
  activeSubscriptionPlan,
  subscriptionPlanType,
  allPlans,
}: {
  activeSubscriptionPlan: PlanCardType["activeSubscriptionPlan"];
  subscriptionPlanType: PossiblePlanKeysNoFree;
  allPlans: AllPlansNoFree;
}) => {
  const sbPlan = {
    [subscriptionPlanType]: allPlans[subscriptionPlanType],
  } as PossiblePlansNoFree;
  const sbPlanValue = extractPlanValue(sbPlan);
  const defaultPrice = findDefaultPriceOfPlan(sbPlan);
  const originalPrice = findOriginalPriceOfPlan(sbPlan);

  if (!defaultPrice || !originalPrice) return null;

  const styleDiscount = getPlanCss(subscriptionPlanType).textDiscountStyle;

  const renderPriceAmount = (originalPrice: number, defaultPrice: number) => {
    if (originalPrice === defaultPrice || defaultPrice > originalPrice)
      return <div className={`lg:text-6xl`}>${defaultPrice / 100}</div>;

    return (
      <div className="priceDiscount flex flex-col">
        <del className="mx-auto w-min !p-0 m-0 h-min">
          ${originalPrice / 100}
        </del>
        <ins className={`mx-auto h-min mt-0 !p-2 glowingText ${styleDiscount}`}>
          ${defaultPrice / 100}
        </ins>
      </div>
    );
  };

  const priceRender = renderPriceAmount(
    originalPrice.amount,
    defaultPrice.amount,
  );

  const upperTextNoTrial = priceRender;
  const lowerTextNoTrial =
    sbPlanValue.billing === "yearly" ? "per year" : "per month";

  const upperTextForFreeTrial = `Free for 1 month`;

  const lowerTextForFreeTrial = (
    <div>
      then {priceRender} $
      {sbPlanValue.billing === "yearly" ? "/year" : "/month"}
    </div>
  );

  const [upperText, lowerText] = shouldShowTrialingDetails(
    activeSubscriptionPlan,
    subscriptionPlanType as PossiblePlanKeys,
  )
    ? [upperTextForFreeTrial, lowerTextForFreeTrial]
    : [upperTextNoTrial, lowerTextNoTrial];

  return (
    <div className="">
      <div className="flex items-center justify-center font-display text-2xl lg:text-4xl font-semibold">
        {upperText}
      </div>

      <div className="text-white-500">{lowerText}</div>
    </div>
  );
};

export const getLogicForInteractivity = ({
  activeSubPlan,
  planSubscribingToType,
  allPlans,
}: {
  activeSubPlan: PlanCardType["activeSubscriptionPlan"];
  planSubscribingToType: PossiblePlanKeys;
  allPlans: AllPlans;
}) => {
  const isSubscribedToThisPlan =
    activeSubPlan.planType === planSubscribingToType;
  const isUpgradeOrDowngrade =
    activeSubPlan.isSubscribed && !isSubscribedToThisPlan;
  const freeTrialAvailable = !activeSubPlan.hasSubscribedAtLeastOnce;
  const activeBilling: "monthly" | "yearly" | false =
    activeSubPlan.activePlan.billing ?? false;
  const isDisabledUpgradeButton = !isAbleToChangePlanFrontend(
    activeSubPlan,
    planSubscribingToType,
    allPlans,
  );

  return {
    isSubscribedToThisPlan,
    isUpgradeOrDowngrade,
    isDisabledUpgradeButton,
    activeBilling,
    freeTrialAvailable,
  };
};

export const getStyles = ({
  isSubscribedToThisPlan,
  isUpgradeOrDowngrade,
  isDisabledUpgradeButton,
  activeBilling,
  freeTrialAvailable,
  baseWrapperForPlanBannerStyle,
  planType,
  pricingStore,
  discount,
  isComingSoon = false,
}: ReturnType<typeof getLogicForInteractivity> & {
  baseWrapperForPlanBannerStyle: string;
  planType: PossiblePlanKeysNoFree;
  pricingStore: PricingStoreType;
  discount?: number;
  isComingSoon?: boolean;
}) => {
  const outerContainerStyle = getOuterContainerStyle({
    object: { isSubscribedToThisPlan },
    planType: planType,
    isComingSoon,
  });
  const wrapperForPlanBannerStyle = getWrapperForPlanBannerStyle({
    baseWrapperForPlanBannerStyle,
    isUpgradeOrDowngrade,
    isSubscribedToThisPlan,
    planType,
    discount,
    isComingSoon,
  });

  const planStyles = getPlanCss(planType);
  if (!pricingStore.plans) return null;
  const planToUpgradeTo = pricingStore.plans[planType];
  const renderFooter = () => {
    return (
      <UpgradeButton
        activeBilling={activeBilling}
        isDisabled={isDisabledUpgradeButton}
        isSubscribedToThisPlan={isSubscribedToThisPlan}
        freeTrialAvailable={freeTrialAvailable}
        planToUpgradeTo={planToUpgradeTo}
        buttonStyle={planStyles.upgBtnStyle}
        pricingStore={pricingStore}
        isComingSoon={isComingSoon}
      />
    );
  };

  return { outerContainerStyle, wrapperForPlanBannerStyle, renderFooter };
};
