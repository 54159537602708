"use client";

import { AllPricingItems } from "@/lib/server/pricing/types";
import { PlanCardType } from "../types/types";
import PlanBase from "./PlanBase";
import PriceCard from "./PriceCard";
import { getOuterContainerStyle } from "./utils";

const GoldPlanCard = ({
  baseWrapperForPlanBannerStyle,
  activeSubscriptionPlan,
  billing,
  pricingItems,
}: PlanCardType) => {
  const planType = billing === "monthly" ? "goldPlan" : "goldPlanYearly";
  return (
    <PlanBase
      baseWrapperForPlanBannerStyle={baseWrapperForPlanBannerStyle}
      activeSubscriptionPlan={activeSubscriptionPlan}
      planType={planType}
      billing={billing}
      pricingItems={pricingItems}
    />
  );
};

const GoldPlanViewOnly = ({
  pricingItems,
}: {
  pricingItems: AllPricingItems;
}) => {
  const goldPricingItem = pricingItems["goldPlan"];
  const outerContainerStyle = getOuterContainerStyle({
    object: { isViewOnly: true },
    planType: "goldPlan",
  });

  if (!goldPricingItem) return null;
  return (
    <PriceCard
      isAdmin={false} //no need to show edit overlay here
      outerContainerStyle={outerContainerStyle}
      wrapperForPlanBannerStyle={"hidden"}
      wrapperForPlanBannerText=""
      planTitle={goldPricingItem.plan}
      tagline={goldPricingItem.tagline}
      priceDisplay={<></>}
      cardMiddleZone={
        <>{goldPricingItem.quota.toLocaleString()} submissions/month</>
      }
      features={goldPricingItem.features}
      footer={<></>}
      cancellationNotice={<></>}
      isComingSoon={goldPricingItem.comingSoon}
    />
  );
};

export { GoldPlanCard, GoldPlanViewOnly };
