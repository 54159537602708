"use client";
import { PlanCardType } from "../types/types";
import {
  getLogicForInteractivity,
  getStyles,
  renderCancellationNotice,
  renderPriceDisplay,
} from "./utils";
import PriceCard from "./PriceCard";
import {
  findDefaultPriceOfPlan,
  findOriginalPriceOfPlan,
  PossiblePlanKeys,
  PossiblePlansNoFree,
} from "@/lib/shared/pricing/utils";
import { usePricingStore } from "../../stores/pricingStore";
import PlanEdit from "./PlanEdit";
import { useState } from "react";
import { usePricingItemsStore } from "../../stores/pricingItemsStore";
import { useSession } from "next-auth/react";

const PlanBase = ({
  baseWrapperForPlanBannerStyle,
  activeSubscriptionPlan,
  planType,
}: PlanCardType & {
  planType: Exclude<PossiblePlanKeys, "freePlan">;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const pricingStore = usePricingStore();
  const { pricingItems } = usePricingItemsStore();

  const session = useSession();
  const isAdmin = session.data?.user?.role === "admin";

  if (!pricingStore.plans) return null;

  const allPlans = pricingStore.plans;
  const {
    isSubscribedToThisPlan,
    isUpgradeOrDowngrade,
    isDisabledUpgradeButton,
    activeBilling,
    freeTrialAvailable,
  } = getLogicForInteractivity({
    planSubscribingToType: planType,
    activeSubPlan: activeSubscriptionPlan,
    allPlans,
  });

  const sbPlan = {
    [planType]: allPlans[planType],
  } as PossiblePlansNoFree;

  const defaultPrice = findDefaultPriceOfPlan(sbPlan);
  const originalPrice = findOriginalPriceOfPlan(sbPlan);
  const discount = 100 - (defaultPrice!.amount / originalPrice!.amount) * 100;

  const pricingItemSought = pricingItems ? pricingItems[planType] : undefined;
  if (!pricingItemSought) return null;

  const isComingSoon = pricingItemSought.comingSoon;

  const { outerContainerStyle, wrapperForPlanBannerStyle, renderFooter } =
    getStyles({
      isSubscribedToThisPlan,
      isUpgradeOrDowngrade,
      isDisabledUpgradeButton,
      activeBilling,
      freeTrialAvailable,
      baseWrapperForPlanBannerStyle,
      planType,
      pricingStore,
      discount,
      isComingSoon,
    })!;

  return (
    <>
      {isAdmin && (
        <PlanEdit
          plan={allPlans[planType]}
          pricingItem={pricingItemSought}
          isOpen={isModalOpen}
          setIsOpen={() => setIsModalOpen(!isModalOpen)}
        />
      )}
      <PriceCard
        isAdmin={isAdmin}
        openEditModal={() => setIsModalOpen(!isModalOpen)}
        outerContainerStyle={outerContainerStyle}
        wrapperForPlanBannerStyle={wrapperForPlanBannerStyle}
        wrapperForPlanBannerText={
          isComingSoon
            ? "Coming soon"
            : isSubscribedToThisPlan
              ? "Current plan"
              : discount > 0
                ? `Save ${Math.floor(discount)}%`
                : "Upgrade"
        }
        planTitle={pricingItemSought!.plan}
        tagline={pricingItemSought!.tagline}
        priceDisplay={renderPriceDisplay({
          subscriptionPlanType: planType,
          activeSubscriptionPlan: activeSubscriptionPlan,
          allPlans: pricingStore.plans,
        })}
        cardMiddleZone={
          <>{pricingItemSought!.quota.toLocaleString()} submissions/month</>
        }
        features={pricingItemSought!.features}
        footer={renderFooter()}
        cancellationNotice={renderCancellationNotice({
          activeSubscriptionPlan,
          isSubscribedToThisPlan,
        })}
        reverseTextColor={
          planType === "diamondPlan" || planType === "diamondPlanYearly"
        }
        isComingSoon={isComingSoon}
      />{" "}
    </>
  );
};

export default PlanBase;
